import React, {useEffect} from 'react';
import Header, {HeaderImage, HeaderType} from "./Header";
import Footer from "./Footer";
import {setBaseState} from "../../app/reducers/baseDataSlice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import Scroller from "../common/Scroller";
import Api from "../../Api";
import DocumentMeta from 'react-document-meta';
import Cookies from "../common/Cookies";

export type MetaTags = {
    description?:string,
    keywords?:string,
}
export type BaseLayoutProps = {
    children ?: any,
    headerType ?: HeaderType,
    headerSelectedLink ?: string,
    headerImage?:HeaderImage,
    footerNoForm?:boolean,
    footerNoContacts?:boolean,
    footerSmall?:boolean,
    title?:string,
    meta?:MetaTags,
    noTopPadding?:boolean,
    [x:string]:any,
}
const BaseLayout:React.FunctionComponent<BaseLayoutProps> = (props:BaseLayoutProps) => {
    const bState = useAppSelector(state => state.baseData);
    const dispatch = useAppDispatch();
    const meta = {
        title: props.title ?? process.env.REACT_APP_WEBSITE_NAME,
        description: props.meta?.description ?? process.env.REACT_APP_WEBSITE_DESCRIPTION,
        canonical: window.location.href,
        meta: {
            charset: 'utf-8',
            name: {
                keywords: process.env.REACT_APP_WEBSITE_DESCRIPTION+"," + (props.meta?.keywords ?? '')
            }
        }
    };
    useEffect(()=>{
        window.scrollTo({top:0, behavior:'smooth'});
        if(!bState.loaded){
            const loader = async () =>{
                let data = await Api.baseData();
                if(Api.isError(data)){
                    //TODO:Error check!
                    return;
                }
                dispatch(setBaseState(data));
            }
            loader();
        }
    })
    return (
        <DocumentMeta {...meta}>
            <div className={'site'}>
                {!props.noTopPadding && <div style={{height:'80px'}}></div>}
                <Header image={props.headerImage} type={props.headerType ?? 'white'} selectedLink={props.headerSelectedLink ?? '/'}/>
                <main>
                    {bState.loaded && props.children}
                </main>
                <Footer small={props.footerSmall} noContacts={props.footerNoContacts} noForm={props.footerNoForm} />
                <Scroller />
                <Cookies />
            </div>
        </DocumentMeta>

    );
};

export default BaseLayout;